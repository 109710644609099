export default (value: Date | string) => {
  try {
    if (typeof value === 'string') {
      const date = new Date(value)
      if (!date.getTime()) {
        return value
      }
      value = date
    }

    if (value && value instanceof Date) {
      if (!value.getTime()) {
        return value
      }
      return value.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
    }

    return value
  }
  catch {
    return value
  }
}
